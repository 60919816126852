'use client';

import { Microdata } from 'components/microdata';
import { SchemaOrgType } from 'components/microdata/microdata.component';
import {
  TOfferMicrodata,
  getMicrodataFromProduct,
} from 'components/microdata/microdata.utils';
import { ProductColors } from 'components/product-colors';
import { Spacer, Typo } from 'components/ui-kit';
import { OfferModel, TProductResponse } from 'lib';
import React, { useCallback, useState } from 'react';
import { ProductCardImage, ProductCardPrice } from './components';
import styles from './product-card.module.scss';

export type TProductCardProps = {
  /**
   * Продукт
   */
  product: TProductResponse;
  /**
   * Показывать вариации цветов продукта
   */
  isShowColors?: boolean;

  /**
   * Отцентрировано ли описание(название с ценой)
   */
  isDescriptionCentered?: boolean;

  /*
   * Используется для передачи высоты полученного изображения во вне
   */
  onChangeImageHeight?: (imageHeight: number) => void;
  /*
   * Колбек для кастомного отображения заголовка
   */
  renderTitle?(product: TProductResponse): string;
};

export const ProductCard = ({
  product,
  isDescriptionCentered = false,
  onChangeImageHeight,
  isShowColors = false,
  renderTitle,
}: TProductCardProps): JSX.Element => {
  const { offers, previewImage, name } = product;
  const [activeImage, setActiveImage] = useState(previewImage?.src);
  const isOffersExist = offers && offers.length > 0;

  const handleColorHover = useCallback((offer: OfferModel) => {
    setActiveImage(offer.previewImage.src);
  }, []);

  const handleColorBlur = useCallback(() => {
    setActiveImage(previewImage?.src);
  }, [previewImage]);

  const tag = product.labels?.[0]?.name;
  const { offers: offersMicrodata, ...productMicrodata } =
    getMicrodataFromProduct(product);

  return (
    <>
      <div className={styles.productCart}>
        {tag && <div className={styles.tag}>{tag}</div>}
        {activeImage && (
          <>
            <div className={styles.productCardImage}>
              <ProductCardImage
                thumbnail={activeImage}
                name={name}
                onChangeImageHeight={onChangeImageHeight}
              />
            </div>
            <Spacer size={16} />
          </>
        )}
        <Typo
          variant="2xl"
          align={isDescriptionCentered ? 'center' : 'left'}
          weight="600"
        >
          {renderTitle?.(product) ?? name}
        </Typo>
        {isOffersExist && !product.subscription ? (
          <>
            <Spacer size={8} />
            <ProductCardPrice
              isCenter={isDescriptionCentered}
              offer={product.offers[0]}
            />
            {isShowColors && (
              <>
                <Spacer size={8} />
                <ProductColors
                  offers={offers}
                  onColorHover={handleColorHover}
                  onColorBlur={handleColorBlur}
                />
              </>
            )}
          </>
        ) : null}
      </div>

      <Microdata type={SchemaOrgType.Product} items={productMicrodata}>
        {offersMicrodata?.map(
          (offerMicrodata: TOfferMicrodata, index: number) => (
            <Microdata
              prop="offers"
              type={SchemaOrgType.Offer}
              key={
                String(offerMicrodata.price) +
                offerMicrodata.priceCurrency +
                String(index)
              }
              items={offerMicrodata}
            />
          ),
        )}
      </Microdata>
    </>
  );
};
