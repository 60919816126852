'use client';
import { useSizeObserver } from '@brand/utils';
import { productPlaceholder } from '_shared/constants/product-placeholder.constant';
import { SimpleImage } from 'components/simple-image';
import { SyntheticEvent, useEffect, useRef } from 'react';

interface IProductCardImageProps {
  name: string;
  thumbnail: string;
  onChangeImageHeight?: (height: number) => void;
}

export const ProductCardImage = ({
  name,
  thumbnail,
  onChangeImageHeight,
}: IProductCardImageProps): JSX.Element => {
  const ref = useRef<HTMLImageElement>(null);
  const { height } = useSizeObserver(ref);

  useEffect(() => {
    onChangeImageHeight?.(height as number);
  }, [height, onChangeImageHeight]);

  return (
    <SimpleImage
      ref={ref}
      className="aspect-ratio-10-13"
      blurDataURL={productPlaceholder}
      placeholder="blur"
      layout="responsive"
      objectFit="cover"
      width={1024}
      height={768}
      src={thumbnail}
      alt={name}
      onLoad={({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>) => {
        onChangeImageHeight?.(currentTarget.offsetHeight);
      }}
    />
  );
};
