import { OfferModel, PropertyModel } from 'lib';
import { useMemo } from 'react';
import { ProductColor } from './components';
import styles from './product-colors.module.scss';

type TProductColorsProps = {
  /**
   * Список всех предложений у товара
   */
  offers: Array<OfferModel>;

  /**
   * Обработчик события по наведению на цвет
   */
  onColorHover?: (offer: OfferModel) => void;

  /**
   * Обработчик события по отведению курсора от цвета
   */
  onColorBlur?: () => void;

  /**
   * Обработчик события по клику на цвет
   */
  onColorClick?: (offer: OfferModel) => void;
};

// Уникальный код, по которому можно найти информацию о цвете в предложениях продукта
const PROPERTY_COLOR_CODE = 'color_code';

export const ProductColors = ({
  offers,
  onColorHover = () => void 0,
  onColorBlur = () => void 0,
  onColorClick = () => void 0,
}: TProductColorsProps): JSX.Element => {
  const getColorProperty = (offer: OfferModel): PropertyModel | undefined =>
    offer.property?.find(
      (property: PropertyModel) => property.code === PROPERTY_COLOR_CODE,
    );

  const setBackgroundColor = (offer: OfferModel): string | undefined =>
    getColorProperty(offer)?.value ?? 'none';

  const isWhite = (offer: OfferModel): boolean => {
    const color = getColorProperty(offer)?.value ?? '';

    return color === '#ffffff' || color === '#fff';
  };

  const uniqueOffersByColor = useMemo<Array<OfferModel>>(() => {
    const result = offers.reduce(
      (prevOffers: Array<OfferModel>, currentOffer: OfferModel) => {
        if (!getColorProperty(currentOffer)) return prevOffers;

        if (
          !prevOffers.find(
            (offer: OfferModel) =>
              getColorProperty(offer)?.value ===
              getColorProperty(currentOffer)?.value,
          )
        ) {
          return [...prevOffers, currentOffer];
        }

        return prevOffers;
      },
      [],
    );

    return result;
  }, [offers]);

  return (
    <div className={styles.component}>
      {uniqueOffersByColor.map((offer: OfferModel) => (
        <ProductColor
          key={offer.id}
          hasShadow={isWhite(offer)}
          value={setBackgroundColor(offer) ?? '#fff'}
          onMouseMove={() => onColorHover(offer)}
          onMouseLeave={onColorBlur}
          onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.preventDefault();
            onColorClick(offer);
          }}
        />
      ))}
    </div>
  );
};
