import cn from 'classnames';
import { DOMAttributes } from 'react';
import styles from './product-color.module.scss';

type TProductColorProps = {
  /**
   * Значение цвета (HEX, RGBA, RGBA и тд.)
   */
  value: string;

  /**
   * Активен ли цвет
   */
  isActive?: boolean;

  /**
   * Размер элемента
   */
  size?: 's' | 'm';

  /**
   * Есть ли тень вокруг цвета
   */
  hasShadow?: boolean;
} & DOMAttributes<HTMLElement>;

export const ProductColor = ({
  value,
  isActive = false,
  size = 's',
  hasShadow = false,
  ...props
}: TProductColorProps): JSX.Element => {
  return (
    <label
      className={cn(styles.component, styles[size], {
        [styles.active]: isActive,
        [styles['color-shadow']]: hasShadow,
      })}
      {...props}
    >
      <span style={{ backgroundColor: value }} />
    </label>
  );
};
