import { OfferModel, TProductResponse } from 'lib';

export type TOfferMicrodata = {
  priceCurrency: string;
  price: number;
};

export type TProductMicrodata = {
  name: string;
  description: string;
  image: string;
  offers?: Array<TOfferMicrodata>;
};

export const getMicrodataFromProduct = (
  product: TProductResponse,
): TProductMicrodata => {
  return {
    name: product.name,
    description: product.description || product.name,
    image: product.previewImage?.src ?? '',
    offers: product.offers.map((offer: OfferModel) => ({
      priceCurrency: 'RUB',
      price: offer.prices?.regular?.priceValue,
    })),
  };
};
