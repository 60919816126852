'use client';
import cn from 'classnames';
import { Typo } from 'components/ui-kit';
import { MoneyFormatter, OfferModel, OfferPrice } from 'lib';
import { Currency } from 'lib/formatters/money.formatter';
import styles from './product-card-price.module.scss';
import { ProductPrice } from '../../../product-price/product-price';

type TProductCardPriceProps = {
  currency?: Currency;
  isCenter?: boolean;
  isBig?: boolean;
  offer?: OfferModel;
};

export const ProductCardPrice = ({
  currency = Currency.RUB,
  isCenter = false,
  isBig = false,
  offer,
}: TProductCardPriceProps): JSX.Element => {
  return (
    <div
      className={cn(styles.priceContainer, {
        [styles.priceContainerCenter]: isCenter,
      })}
    >
      <ProductPrice offer={offer}>
        {({ priceValue, oldPriceValue }: OfferPrice) => {
          const isPriceChanged =
            oldPriceValue !== 0 && oldPriceValue !== priceValue;
          return (
            <>
              {isPriceChanged ? (
                <Typo
                  color="accent"
                  variant={isBig ? '3xl' : 'l'}
                  className={styles.priceNew}
                  weight="500"
                >
                  {MoneyFormatter.format(priceValue, { currency })}
                  <Typo
                    weight="500"
                    color="gray400"
                    variant={isBig ? 'xl' : 'l'}
                    className={styles.priceOld}
                  >
                    {oldPriceValue
                      ? MoneyFormatter.format(oldPriceValue, { currency })
                      : undefined}
                  </Typo>
                </Typo>
              ) : (
                <Typo
                  weight="500"
                  className={styles.priceNew}
                  variant={isBig ? '3xl' : 'l'}
                >
                  {MoneyFormatter.format(priceValue, { currency })}
                </Typo>
              )}
            </>
          );
        }}
      </ProductPrice>
    </div>
  );
};
